import {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import user from "../Models/user"
import axios from "axios";
import SalesIndex from "./SalesIndex";
import TableExample from "./TableExample";
import global from '../global';
import {numberFormat, priceFormat} from "../utils/helper";
import ContentLoader from "react-content-loader";
import HeaderTop from "../Components/HeaderTop";

export default function Check() {
    const [info, setInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [totalBillsAmount, setTotalBillsAmount] = useState(0)
    const [billsAndCoins, setBillsAndCoins] = useState([
        {
            'value': 1,
            'currency':'c',
            'label': '1c',
            'count' : 0,
        },
        {
            'value': 2,
            'currency':'c',
            'label': '2c',
            'count' : 0,
        },
        {
            'value': 5,
            'currency':'c',
            'label': '5c',
            'count' : 0,
        },
        {
            'value': 10,
            'currency':'c',
            'label': '10c',
            'count' : 0,
        },
        {
            'value': 20,
            'currency':'c',
            'label': '20c',
            'count' : 0,
        },
        {
            'value': 50,
            'currency':'c',
            'label': '50c',
            'count' : 0,
        },
        {
            'value': 100,
            'currency':'€',
            'label': '1€',
            'count' : 0,
        },
        {
            'value': 200,
            'currency':'€',
            'label': '2€',
            'count' : 0,
        },
        {
            'value': 500,
            'currency':'€',
            'label': '5€',
            'count' : 0,
        },
        {
            'value': 1000,
            'currency':'€',
            'label': '10€',
            'count' : 0,
        },
        {
            'value': 2000,
            'currency':'€',
            'label': '20€',
            'count' : 0,
        },
        {
            'value': 5000,
            'currency':'€',
            'label': '50€',
            'count' : 0,
        },
        {
            'value': 10000,
            'currency':'€',
            'label': '100€',
            'count' : 0,
        },
        {
            'value': 20000,
            'currency':'€',
            'label': '200€',
            'count' : 0,
        },
        {
            'value': 50000,
            'currency':'€',
            'label': '500€',
            'count' : 0,
        },
    ])

    const  setMoneyBills = index => e => {
        const  bills  = billsAndCoins
        bills[index].count = e.target.value
        setBillsAndCoins(bills)
      }
    const  checkBills = () => {

        const totalAmount = billsAndCoins.reduce(
            (total, item) => total + (item['count'] * item['value']), 0
        ) / 100;

        setTotalBillsAmount(totalAmount)
        console.log(totalAmount)

    }

    const  clearBills = () => {

        window.location.reload()

    }

    const fetchData = async () => {
        setInfo({})
        setIsLoading(true)
        let fetchUrl = `/reports/info`;
        const result = await window.axios.get(fetchUrl)
        setInfo(result.data.data)
        console.log(result.data)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        // setProducts(result.data)
    }, [])


    return (

    <>
        <HeaderTop title={'Provjera blagajne'}/>
        <div className=" w-full h-full flex flex-column " style={{inHeight: `calc(100vh - 80px)`,}}>
   <div className="w-full flex-grow flex ">
            <div className="flex flex-col bg-blue-gray-50 h-full w-full">
                <div className="h-full overflow-hidden mt-4 flex">
                    <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Gotovina u blagajni</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Ukupan iznosa gotivine u blagajni</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Datum
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{info.from}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Gotovina</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">
                                    {isLoading === false && info.payment_method_cash && priceFormat(info.payment_method_cash.total_with_tax)}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Polog blagajne</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{priceFormat(global.invoiceDevice.deposit)}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Ukupno</dt>
                                        <dd className="mt-1 leading-6 text-gray-700  font-bold text-xl">{priceFormat((info.total_cash))}
                                        </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </div>



                    <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Provjera blagajne</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Provjera gotovine u blagajni</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">

                                      <div className="grid grid-cols-2 gap-2 mt-2">
                                        {billsAndCoins.map((money, index) => (
                                            <div key={money.value} className="relative">
                                                <div className="g-gray-10  absolute inset-y-0 start-0 top-0 flex items-center ps-6 mr-7 pointer-events-none">
                                                    { money.label }
                                                </div>
                                                <input onChange={setMoneyBills(index)}  type="number" className="bg-gray-10 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full ps-20 p-2.5 "  placeholder={'0'} required/>
                                            </div>
                                        ))}
                                    <button  className="text-blue-gray-300 hover:text-pink-500 focus:outline-none" onClick={e => clearBills()}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                    </button>
                                      </div>
                                    <hr className="mt-4"/>
                                    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Izbrojeno</dt>
                                        <dd className="mt-1 leading-6 text-gray-700  font-bold text-xl">{priceFormat((totalBillsAmount))}
                                        </dd>
                                    </div>
                                   {totalBillsAmount > 0 && totalBillsAmount !== info.total_cash &&
                                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Razlika</dt>
                                        <dd className={"mt-1 leading-6 text-green-500  font-bold text-xl " + ((totalBillsAmount - info.total_cash > 0) ? 'text-gray-700' : 'text-red-500')}>
                                            {priceFormat((totalBillsAmount - info.total_cash))}
                                        </dd>
                                    </div>}
                                    <button onClick={() => checkBills()}  className={" font-bold text-base rounded-sm w-full py-3 mt-4 focus:outline-none  bg-primary hover:bg-yellow-600 text-black"}>
                                        Provjeri
                                    </button>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>

    );
}