import {useState, useEffect} from "react";

import global from '../global';
import {priceFormat} from "../utils/helper";
import ContentLoader from "react-content-loader";
import HeaderTop from "../Components/HeaderTop";
import {useOutletContext} from "react-router-dom";

export default function Version() {

    const { appVersion,  newVersion, versionCheckInterval, lastChecked, showNewVersion} = useOutletContext();
    function hardReloadPage() {
        window.location.reload(true);
    }
    return (
        <>
            <HeaderTop title={'Promet blagajne'}/>
            <div className=" w-full h-full flex flex-column " style={{inHeight: `calc(100vh - 80px)`,}}>
                <div className="w-full flex-grow flex ">
                    <div className="flex flex-col bg-blue-gray-50 h-full w-full">
                        <div className="h-full overflow-hidden mt-4 flex">

                            <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                                <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Informacije</h3>
                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Informacije o verziji</p>
                                    </div>
                                    <div className="mt-6 border-t border-gray-100">
                                        <dl className="divide-y divide-gray-100">
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Lokalna verzija:</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{appVersion}</dd>
                                            </div>
                                        </dl>
                                        <dl className="divide-y divide-gray-100">
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Dostupna verzija:</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{newVersion}</dd>
                                            </div>
                                        </dl>
                                        <dl className="divide-y divide-gray-100">
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Interval provjere:</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{versionCheckInterval / 1000}</dd>
                                            </div>
                                        </dl>
                                        <dl className="divide-y divide-gray-100">
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Zadnja provjera:</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{lastChecked?.toLocaleString()}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>


                            </div>



                            <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                                <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Nadogradnja</h3>
                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Informacije o nadogradnji</p>
                                    </div>
                                    <div className="mt-6 border-t border-gray-100 justify-center text-center">
                                          {showNewVersion && (
                                            <div>
                                              <h3 className="text-2xl  font-semibold leading-7 text-gray-900 mt-4">Dostupna je nova verzija</h3>
                                              <p>Dostupna je nova verzija aplikacije. Da li želite nadogradnju?</p>
                                              <p>Vaša verzija: {appVersion}</p>
                                              <p>Nova verzija: {newVersion}</p>
                                              <button className="mt-4 rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-primary-600 " onClick={hardReloadPage}>Ažuriraj sada</button>
                                            </div>
                                          )}
                                          {!showNewVersion && (
                                            <div>
                                              <h3 className="text-2xl  font-semibold leading-7 text-gray-900 mt-4">Ažurirano</h3>
                                              <p>Aplikacija je ažurirana na najnoviju verziju</p>
                                              <p>Vaša verzija: {appVersion}</p>
                                            </div>
                                          )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}