import React, {useState, useEffect, useRef, useCallback} from 'react'
import ReactDOM from 'react-dom'
import {Outlet, Link, useLocation} from "react-router-dom";
import {dateFormat, showError, showSuccess} from '../utils/helper'
import global from '../global';

import beepSound from '../assets/sound/beep-29.mp3'
import beepButton from '../assets/sound/button-21.mp3'


import Layout from '../Components/Layout'
import LeftSidebar from '../Components/LeftSidebar'

import SelectPosModal from "../Components/SelectPosModal";
import HeaderTop from '../Components/HeaderTop'

const appVersion = process.env.REACT_APP_POS_VERSION;
const versionCheckInterval =  process.env.REACT_APP_POS_VERSION_CHECK_INTERVAL;

const App = (props) => {


    const [newVersion, setNewVersion] = useState("");
    const [lastChecked, setLastChecked] = useState(null);

    const location = useLocation();
    const { hash, pathname} = location;
    const [invoiceDevices, setInvoiceDevices] = useState({})
    const [showSelectPosModal, setShowSelectPosModal] = useState(true)

    useEffect(() => {

        var invoiceDeviceTmp = JSON.parse(localStorage.getItem('invoiceDevice'));

        if(invoiceDeviceTmp != null && invoiceDeviceTmp.id) {

            global.invoiceDevice = invoiceDeviceTmp
            setShowSelectPosModal(false)
        }else {
            setShowSelectPosModal(true);
        }
    }, [])

  const fetchRemoteVersion = useCallback(
    () =>
      fetch(`/version.json?${Date.now()}`, {
        cache: "no-store",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setNewVersion(data.version);
          setLastChecked(new Date());
          return data.version;
        }),
    []
  );

    const [showNewVersion, setShowNewVersion] = useState(false);
  function hardReloadPage() {
    window.location.reload(true);
  }

  useEffect(() => {
    /** initial run and update without prompt */
    fetchRemoteVersion().then((version) => {
      if (version !== appVersion) {
        hardReloadPage();
      }
    });

    /** interval run and show prompt if update is needed */
    const timer = setInterval(() => {
      fetchRemoteVersion().then((version) => {
        if (version !== appVersion) {
          setShowNewVersion(true);
          showSuccess('Dostupna je nova verzija aplikacije! Učitajte najnoviju verziju aplikacije!')
        } else {
          console.log("version not changed");
        }
      });
    }, versionCheckInterval);
    return () => clearInterval(timer);
  }, [fetchRemoteVersion]);


    const showInvoiceDeviceSelect = () => {
        setShowSelectPosModal(true);
    }

    return (
        <>
            <Layout>
                <LeftSidebar />

                <div className="flex flex-col w-full"  >
                    <Outlet  context={{ appVersion,  newVersion, versionCheckInterval, lastChecked, showNewVersion}}/>
                </div>
            </Layout>

            <SelectPosModal
                showSelectPosModal={showSelectPosModal}
                setShowSelectPosModal={setShowSelectPosModal}
            />

        </>
    )
}

export default App