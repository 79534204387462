import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useRef, useState, useEffect } from 'react'
import Icon from '@mdi/react';
import {mdiBarcodeScan, mdiFullscreen, mdiFullscreenExit, mdiRefresh} from '@mdi/js';
import user from "../Models/user"
import { useNavigate, useLocation  } from 'react-router-dom'
import {printReceipt} from "../Helpers/receipt";
import global from '../global';
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from 'react-tooltip'
import '../css/tooltip.css'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const  HeaderTop = (props) => {

  const searchRef = useRef(null);

  const { title, displaySearch, runSearch, scanActive, barcodeScanner} = props;
  const location = useLocation();
  const { hash, pathname, search } = location;

  const [fullscreen, setFullscreen] = useState(false)

  const navigate = useNavigate()

  const elem = document.documentElement;


    const logout = async () => {
        if(window.confirm('Kompletna odjava?')) {
            await window.axios.post('/logout')
            .finally(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('invoiceDevice')
            user.logout({})

            window.axios.defaults.headers.common['Authorization'] = 'Bearer'
            navigate('/login')
        })
        }
    }

  function handleClick() {

      if(pathname !== "/" && pathname !== "/return") {
        navigate('/')
      }
    runSearch(searchRef.current.value)
  }

     function toggleFullScreen() {
       if (!document.fullscreenElement &&    // alternative standard method
        !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
           setFullscreen(true)
         if (document.documentElement.requestFullscreen) {
           document.documentElement.requestFullscreen();
         } else if (document.documentElement.mozRequestFullScreen) {
           document.documentElement.mozRequestFullScreen();
         } else if (document.documentElement.webkitRequestFullscreen) {
           document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
         }
       } else {
           setFullscreen(false)
          if (document.cancelFullScreen) {
             document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
             document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
       }
     }

  return (
    <>
                <Tooltip id="tooltip-reload" />
                <Tooltip id="tooltip-fullscreen" />
        <Tooltip id="tooltip-scan2" />
      <div className=" pl-2 pr-4  pt-4 bg-blue-gray-50">
        <Disclosure as="nav" className="bg-secondary rounded-sm">
          {({ open }) => (
            <>
              <div className="mx-auto  px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                            <div className="text-lg font-semibold"><span className="text-lg font-semibold text-primary">{global.invoiceDevice.invoice_space_name} ({global.invoiceDevice.invoice_space_label}-{global.invoiceDevice.label})</span> </div>
                    </div>

                      {title &&
                        <div className="ml-20 flex items-center content-center space-x-4 w-96">
                          <div className="relative flex w-full flex-wrap items-stretch">
                              <span className="text-lg font-semibold text-white">{title}</span>
                          </div>
                        </div>
                      }

                     {displaySearch && <div className="ml-20 flex items-center content-center space-x-4 w-96">
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <input
                              type="search"
                              className="relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] font-normal leading-[1.6] text-white outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-white  focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                              placeholder="Pretraži..."
                              ref={searchRef}
                            />

                            <button
                              className="relative  flex items-center rounded-r bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                              type="button"
                              onClick={handleClick}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="h-5 w-5">
                                <path
                                  fillRule="evenodd"
                                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                  clipRule="evenodd" />
                              </svg>
                            </button>
                          </div>

                      </div> }
                     {displaySearch && <div className="ml-2 flex items-center content-center space-x-2 w-20">
                          <div className="relative flex w-full flex-wrap items-stretch">
                            <button
                                data-tooltip-id="tooltip-scan" data-tooltip-content="Barcode skener" data-tooltip-place="bottom"
                              className={(scanActive ? 'bg-green-500' : 'bg-primary') + " relative  flex items-center rounded px-6 py-2 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"}
                              type="button"

                              onClick={(e) => {
                                  e.target.blur()
                                  document.activeElement.blur()
                                  barcodeScanner()
                                }
                              }>
                              <Icon path={mdiBarcodeScan} size={1} />
                            </button>
                          </div>

                      </div> }
                  </div>

                    <div className="ml-4 flex items-center md:ml-6">

                        <div className="text-lg font-semibold mr-4">
                            <span className="text-lg font-semibold text-primary">{user.username}</span>
                        </div>
                        <button value="Refresh Page" onClick={() => window.location.reload(true)} className="relative rounded-full bg-primary p-1 text-black  focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"   data-tooltip-id="tooltip-reload" data-tooltip-content="Ponovo učitavanje" data-tooltip-place="bottom" >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Refresh</span>
                        <Icon path={mdiRefresh} size={1} />
                      </button>
                      <button
                        type="button"
                        onClick={()=>toggleFullScreen()}
                        className=" ml-3 relative rounded-full bg-primary p-1 text-black  focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" data-tooltip-id="tooltip-fullscreen" data-tooltip-content="Puni zaslon" data-tooltip-place="bottom"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Fullscreen</span>
                        <Icon path={fullscreen ? mdiFullscreenExit : mdiFullscreen} size={1} />
                      </button>
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-400">
                                <span className="font-medium text-gray-900 dark:text-gray-900">{user.name.charAt(0)}{user.surname.charAt(0)}</span>
                            </div>
                          </Menu.Button>
                        </div>

                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                              <Menu.Item key="+salesid">
                                    <NavLink to="/sales" className='block px-4 py-2 text-sm text-gray-700'>
                                        Računi
                                    </NavLink>
                              </Menu.Item>
                              <Menu.Item key="logoutid">
                                  <a
                                      href="#"
                                    onClick={logout}
                                    className='block px-4 py-2 text-sm text-gray-700'
                                  >
                                    Kompletna odjava?
                                  </a>
                              </Menu.Item>
                              <Menu.Item key="versionid">
                                    <NavLink to="/version" className='block px-4 py-2 text-sm text-gray-700'>
                                        Verzija aplikacije
                                    </NavLink>
                              </Menu.Item>
                          </Menu.Items>
                      </Menu>
                    </div>

                </div>
              </div>

            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}

export default HeaderTop