import axios from 'axios'
import {redirect} from 'react-router-dom'

const posUrl = process.env.REACT_APP_POS_API_URL;

export const setApi = () => {

    window.axios = axios
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    window.axios.defaults.baseURL = posUrl

    let invoiceDevice = {}

    if (localStorage.getItem('token')) {
        window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    }

    if (localStorage.getItem('invoiceDevice')) {
        invoiceDevice = JSON.parse(localStorage.getItem('invoiceDevice'))
        window.axios.defaults.params = {}
        window.axios.defaults.params['invoice_device_id'] = invoiceDevice.id
    }

    window.axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401) {
                localStorage.removeItem('token')
                window.axios.defaults.headers.common['Authorization'] = 'Bearer'
                redirect('/login')
            }

            return Promise.reject(error);
        }
    );

}

export default setApi;