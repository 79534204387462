import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
//import App from './App';
import {BrowserRouter, Routes, Route, redirect} from 'react-router-dom'
import {setApi} from "./utils/api";
import { ToastContainer } from 'react-toastify';

import Login from './Views/Login'
import Profile from './Views/Profile'
import ProtectedRoute from "./Components/ProtectedRoute";

import Guest from "./Layouts/Guest";
import App from "./Layouts/App";
import ProductMode from "./pages/ProductMode";
import Settings from "./pages/Settings";
import Page from "./Layouts/Page";
import Sales from "./Views/Sales";
import Info from "./Views/Info";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Check from "./Views/Check";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from "./Views/ErrorPage";
import Version from "./Views/Version";

setApi()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>

      <ToastContainer
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />
        <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => (window.location.href = '/')}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ProtectedRoute><App/></ProtectedRoute>}>
                    <Route path="/" element={<ProductMode mode={'sale'}/>}/>
                    <Route path="/user" element={<Profile/>}/>
                    <Route path="/return" element={<ProductMode mode={'return'}/>}/>
                    <Route path="/sales" element={<Sales/>}/>
                    <Route path="/info" element={<Info/>}/>
                    <Route path="/version" element={<Version/>}/>
                    <Route path="/check" element={<Check/>}/>
                    {/*<Route path="/settings" element={<Settings/>}/>*/}
                </Route>
{/*                <Route path="/" element={<ProtectedRoute><Page/></ProtectedRoute>}>
                    <Route path="/settings2" element={<Settings/>}/>
                </Route>*/}
                <Route path="/" element={<Guest/>}>
                    <Route path="/login" element={<Login/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
            </ErrorBoundary>
    </React.StrictMode>,
);

//serviceWorkerRegistration.register();

//reportWebVitals();
