import React, { Component } from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import {
    CalendarIcon,
} from "@heroicons/react/24/outline";

export default class DateFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: ""
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Form>
            <Form.Group className="mb-2">
                <Form.Label>{this.props.data.label}</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={(e) => {
                      this.props.applyFilters(this.props.data.name, moment(e).format("DD.MM.YYYY."))
                      this.setState({date: e})
                  }}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <CalendarIcon className="icon icon-xs" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={this.state.date ? moment(this.state.date).format("DD/MM/YYYY") : ""}
                        placeholder="dd/mm/yyyy"
                        onFocus={openCalendar}
                        onChange={() => { }} />
                    </InputGroup>
                  )} />
            </Form.Group>
          </Form>


        )

    }
}