import {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import user from "../Models/user"
import axios from "axios";
import SalesIndex from "./SalesIndex";
import TableExample from "./TableExample";
import global from '../global';
import {priceFormat, processAxiosError, showError, showSuccess} from "../utils/helper";
import ContentLoader from "react-content-loader";
import HeaderTop from "../Components/HeaderTop";
import {printReceipt} from "../Helpers/receipt";
import {getPdf} from "../Helpers/pdf";

export default function Info() {
    const [info, setInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [meta, setMeta] = useState({})
    const [links, setLinks] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const offset = 4
    const navigate = useNavigate()

    const fetchData = async () => {
        setInfo({})
        setIsLoading(true)
        let fetchUrl = `/reports/info`;
        const result = await window.axios.get(fetchUrl)
        setInfo(result.data.data)
        console.log(result.data)
        setIsLoading(false)
    }
    const stornSale = async (sale) => {
        //setProcessing(true)

        await window.axios.delete('/sales/' + sale.id)
            .then((response) => {
                //setProcessing(false)

                console.log(response)
                if (response.status === 201 && response.data.success === true) {
                    console.log(response.data.data)
                    //window.location.reload(false);
                    showSuccess('Račun uspješno stoniran!')
                    printReceipt(response.data.data)
                    fetchData()
                    //setShowReceiptModal(false)

                } else {
                    showError('Došlo je do greške! Error: ' + response.status)
                }
            })
            .catch((error) => {
                //setProcessing(false)
                processAxiosError(error)

            })
    }
    useEffect(() => {
        fetchData()
        // setProducts(result.data)
    }, [])

    const InfoLoading = props => (
        <ContentLoader
            //   width={450}
            height={300}
            //   viewBox=""
            backgroundColor="#ffffff"
            foregroundColor="#f0f0f0"
            {...props}
        >
            <rect x="0" y="235" rx="4" ry="4" width="100" height="9"/>
            <rect x="160" y="235" rx="3" ry="3" width="100" height="9"/>
            <rect x="0" y="10" rx="10" ry="10" width="300" height="217"/>
        </ContentLoader>
    )

    return (
    <>
        <HeaderTop title={'Promet blagajne'}/>
        <div className=" w-full h-full flex flex-column " style={{inHeight: `calc(100vh - 80px)`,}}>
        <div className="w-full flex-grow flex ">
            <div className="flex flex-col bg-blue-gray-50 h-full w-full">
                <div className="h-full overflow-hidden mt-4 flex">
                    <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Promet blagajne</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Ukupan dnevni promet
                                    blagajne</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Datum
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{info.from}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Broj izadnih
                                            računa
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{info.count}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Ukupan promet</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{priceFormat(info.total_with_tax)}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Polog blagajne</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{priceFormat(global.invoiceDevice.deposit)}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Stanje blagajne</dt>
                                        <dd className="mt-1 leading-6 text-gray-700  font-bold text-xl">{priceFormat((info.total_with_deposit))}
                                        </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>

                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Blagajnici</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Dnevni promet prema blagajaniku</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">

                                    {isLoading === false && Array.isArray(info.admins) && info.admins.map(admin => (
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={admin.admin_id}>
                                        <dt className="text-sm font-medium leading-6 text-gray-900">{admin.admin_device_name} ({admin.admin_username})
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{priceFormat(admin.total_with_tax)}
                                        </dd>
                                    </div>

                                    ))}
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Ukupno</dt>
                                        <dd className="mt-1 leading-6 text-gray-700  font-bold text-xl">{priceFormat((info.total_with_tax))}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>



                    <div className="w-1/2 flex flex-col bg-blue-gray-50 h-full bg-white pr-2 pl-2 mb-4">
                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Vrste plaćanja</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Promet prema vrsti plaćanja
                                    blagajne</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">

                                    {isLoading === false && Array.isArray(info.payment_method) && info.payment_method.map(payment => (
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={payment.payment_method_id}>
                                        <dt className="text-sm font-medium leading-6 text-gray-900">{payment.payment_method}
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{priceFormat(payment.total_with_tax)}
                                        </dd>
                                    </div>

                                    ))}
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="  leading-6 text-gray-900 font-bold text-xl">Ukupno</dt>
                                        <dd className="mt-1 leading-6 text-gray-700  font-bold text-xl">{priceFormat((info.total_with_tax))}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        <div className="bg-white rounded-sm flex flex-col h-fit shadow px-4 py-4  mb-4">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-2xl  font-semibold leading-7 text-gray-900">Zadnji račun</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 ">Zadnji izdani račun</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Datum računa
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{info.last_sale && info.last_sale.sale_date}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Iznos računa</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700  font-bold">{info.last_sale && info.last_sale.invoice_total_items_with_tax	} {info.last_sale && info.last_sale.invoice_currency_symbol}
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        {info.last_sale && <button
                                            onClick={() => printReceipt(info.last_sale)}
                                            className="bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 rounded inline-flex items-center">
                                            <span>Ispis</span>
                                        </button>}

{/*                                        <button
                                            onClick={() => getPdf(info.invoice_pdf)}
                                            className="ml-2 bg-green-600 hover:bg-green-800 text-white py-2 px-4 rounded inline-flex items-center">
                                            <span>PDF</span>
                                        </button>*/}

                                        {info.last_sale && info.last_sale.status === 1 && <button
                                            onClick={() => stornSale(info.last_sale)}
                                            className="ml-2 bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded inline-flex items-center">
                                            <span>Storniraj</span>
                                        </button>}
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        </div>
    </>

    );
}