import React, {useState} from 'react'
import {priceFormat, numberFormat} from '../utils/helper'
import {Link, NavLink} from "react-router-dom";


const RightSidebar = (props) => {
    const {
        mode,
        cartItems,
        addToCart,
        clearCart,
        removeFromCart,
        getTotalPrice,
        finish,
        setShowInvoiceTypeModal,
        invoiceDevice,
        calcDiscount,
        discountAmount,
        discountPercent,
        discountActive,
        setDiscountActive
    } = props;

    return (
        <div className="w-3/12 flex flex-col bg-blue-gray-50 h-full bg-white pr-4 pl-2 py-4">

            <div className="bg-white rounded-sm flex flex-col h-full shadow">

                {cartItems.length === 0 ?

                    <div
                        className="flex-1 w-full p-4 opacity-25 select-none flex flex-col flex-wrap content-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 inline-block" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                        </svg>
                        <p>
                            Nema proizvoda!
                        </p>
                    </div>

                    :

                    <div className="flex-1 flex flex-col overflow-auto">
                        <div className="h-16 text-center flex justify-center">
                            <div className="pl-8 text-left text-lg py-4 relative">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 inline-block" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"/>
                                </svg>
                                <div
                                    className="text-center absolute bg-primary text-black w-5 h-5 text-xs p-0 leading-5 rounded-full -right-2 top-3">
                                    {cartItems.reduce((a, b) => a + (b['quantity'] || 0), 0)}
                                </div>
                            </div>
                            <div className="flex-grow px-8 text-right text-lg py-4 relative">
                                <button onClick={clearCart}
                                        className="text-blue-gray-300 hover:text-pink-500 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex-1 w-full px-4 overflow-auto">
                            {cartItems.map(item => (
                                <div key={item.code}
                                     className="select-none mb-3 bg-blue-gray-50 rounded-lg w-full text-blue-gray-700 py-2 px-2 flex justify-center">
                                    <img src={item.picture_url} alt=""
                                         className="rounded-lg h-10 w-10 bg-white shadow mr-2"/>
                                    <div className="flex-grow">
                                        <h5 className="text-sm">{item.name}</h5>
                                        <p className="flex-grow mr-1 text-sm text-black mb-1">{item.product_attribute_name}</p>
                                        <span
                                            className="text-xs block">{item.use_override_price === true ? item.override_price_with_tax_f : item.price_with_tax_f + ' ' + item.currency}</span>
                                    </div>
                                    <div className="py-1">
                                        <div className="w-28 grid grid-cols-3 gap-2 ml-2">
                                            <button onClick={() => removeFromCart(item)}
                                                    className="rounded-lg text-center py-1 text-white bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-3 inline-block"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                          d="M20 12H4"/>
                                                </svg>
                                            </button>
                                            <input readOnly onChange={() => item.quantity} value={item.quantity}
                                                   type="text"
                                                   className="bg-white rounded-lg text-center shadow focus:outline-none focus:shadow-lg text-sm"/>
                                            <button onClick={() => addToCart(item, item.use_reduction_price)}
                                                    className="rounded-lg text-center py-1 text-white bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-3 inline-block"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                }
                {mode != "return" &&
                <ul className="flex flex-row justify-between mt-2 mb-2">
                    <li className="ml-4">
                        <button className={'flex items-center'} onClick={() => setDiscountActive(!discountActive)}>

                                <span
                                    className={discountActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-red-500 shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                          <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
                                        </svg>

                                </span>

                        </button>
                    </li>


                    <li className="ml-3 mr-4">
                        <button className={'flex items-center '} onClick={() => setShowInvoiceTypeModal(true)}>
                            <span
                                className={"flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black"}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                                </svg>
                            </span>
                        </button>
                    </li>

                </ul>
                }
                {discountActive && <div className="flex ml-4 mr-4 text-lg font-semibold text-blue-gray-700">
                    <div>Proizvodi</div>
                    <div className="text-right w-full">
                        {cartItems.length === 0
                            ? priceFormat(0)
                            : priceFormat(getTotalPrice())
                        }
                    </div>
                </div>}
                <div className="select-none h-auto w-full text-center pt-3 pb-4 px-4">
                    {discountActive && (
                        <div
                            className="flex flex-row justify-between align-center mb-3 text-lg font-semibold text-blue-gray-700">
                            <div className="mr-5">Popust</div>
                            <div className="flex flex-row">

                                <div className="relative rounded-md shadow-sm mr-4">
                                    <div
                                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span className="text-gray-500 sm:text-sm">%</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        value={discountPercent}
                                        onChange={e => calcDiscount(e.target.value, "percent")}
                                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
                                        placeholder="0.00"
                                    />
                                </div>
                                <div className="relative rounded-md shadow-sm">
                                    <div
                                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <span className="text-gray-500 sm:text-sm">€</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        value={discountAmount}
                                        onChange={e => calcDiscount(e.target.value, "amount")}
                                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>
                        </div>
                    )}


                    <div className="flex mb-3 text-lg font-semibold text-blue-gray-700">
                        <div>UKUPNO</div>
                        <div className="text-right w-full">
                            {cartItems.length === 0
                                ? priceFormat(0)
                                : priceFormat(getTotalPrice(true))
                            }
                        </div>
                    </div>

                    {mode == "return" ? <button onClick={() => finish()} disabled={cartItems.length <= 0}
                                                className={" font-bold text-base rounded-sm w-full py-3 focus:outline-none " + (cartItems.length > 0 ? "bg-red-600 hover:bg-red-700 text-black" : "bg-red-600 text-gray-500")}>
                        POVRAT
                    </button> : <button onClick={() => finish()} disabled={cartItems.length <= 0}
                                        className={" font-bold text-base rounded-sm w-full py-3 focus:outline-none " + (cartItems.length > 0 ? "bg-primary hover:bg-yellow-600 text-black" : "bg-secondary text-gray-500")}>
                        ZAVRŠI
                    </button>}

                </div>
            </div>
        </div>
    )
}

export default RightSidebar
