import {toast} from "react-toastify";

export const numberFormat = (number) => {

    return (Math.round(number) || "")
        .toString()
        .replace(/^0|\./g, "")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export const priceFormat = (number) => {

    const formatter = new Intl.NumberFormat('hr', {
      style: 'currency',
      currency: 'EUR',
    });

    return number ? `${formatter.format(number)}` : `0,00 €`;
}

export const dateFormat = (date) => {
    const formatter = new Intl.DateTimeFormat('id', { dateStyle: 'short', timeStyle: 'short'});
    return formatter.format(date);
}

export const showError = (msg) => {

    toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
}

export const showSuccess = (msg) => {

    toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
}

export const processAxiosError = (error) => {

    if(!error) {
        return;
    }

    if(Object.hasOwn(error, 'response')) {
        console.log(error);
        showError('Došlo je do greške! Error: ' + error)
        return;
    }

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      if (error.response.data.error) {
          showError('Došlo je do greške! Error: ' + error.response.data.error)

      } else {
          showError('Došlo je do greške! Error: ' + error.response.status)
      }

    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      showError('Došlo je do greške zahtjeva! Error: ' + error.response.status)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      showError('Došlo je do greške! Error: ' + error.message)
    }
    console.log(error.config);

}