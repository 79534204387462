import React, {useState, useEffect} from 'react'
import {useLocation, useOutletContext} from "react-router-dom";
import ReceiptModal from '../Components/ReceiptModal'
import RightSidebar from '../Components/RightSidebar'

import api from '../utils/api'
import InvoiceSettingsModal from "../Components/InvoiceSettingsModal";
import ProductModal from "../Components/ProductModal";
import InvoiceTypeModal from "../Components/InvoiceTypeModal";
import {dateFormat, priceFormat, showError} from '../utils/helper'
import global from '../global';

import ProductBox from "../Components/ProductBox";
import NoResults from "../Components/NoResults";
import ProductLoading from "../Components/ProductLoading";
import Pagination from "../Components/Pagination";
import beepSound from '../assets/sound/beep-29.mp3';
import beepButton from '../assets/sound/button-21.mp3';
import HeaderTop from "../Components/HeaderTop";

const ProductMode = (props) => {

    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [meta, setMeta] = useState({})
    const [links, setLinks] = useState({})
    const location = useLocation();
    const {hash, pathname} = location;
    const {mode} = props;
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState({})
    const [cartItems, setCartItems] = useState([])
    const [cash, setCash] = useState(0)
    const [change, setChange] = useState(0)
    const [showReceiptModal, setShowReceiptModal] = useState(false)
    const [showInvoiceSettingsModal, setShowInvoiceSettingsModal] = useState(false)
    const [receipt, setReceipt] = useState({})
    const [paymentMethod, setPaymentMethod] = useState('G')
    const [invoiceType, setInvoiceType] = useState('G')
    const [printType, setPrintType] = useState('T')
    const [invoiceCompany, setInvoiceCompany] = useState({
        company: '',
        oib: '',
        address: '',
        zip: '',
        city: '',
        country: 'Hrvatska'
    })
    const [showInvoiceTypeModal, setShowInvoiceTypeModal] = useState(false)
    const [discountAmount, setDiscountAmount] = useState(0.00)
    const [discountPercent, setDiscountPercent] = useState(0)
    const [discountActive, setDiscountActive] = useState(false)
    const [showProductModal, setShowProductModal] = useState(false)

    const [scanActive, setScanActive] = useState(true)

    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)

    const handleScan = (barcodeString) => {

        console.log("handle: " + barcodeString)

        //setBarcodeDisplay(barcodeString);

        getProduct(barcodeString).then(r => {
            console.log(pathname);
            if (r) {
                setShowProductModal(true)
            }
        })
    }

    let barcodeScan = '';

    useEffect(() => {
        //console.log("radim....")
        function handleKeyDown(e) {

            //console.log(e.key + ' - ' +  e.keyCode)

            if (e.keyCode === 13 && barcodeScan.length === 20) {
                console.log("hnadle call")
                handleScan(barcodeScan)
            }

            /*if(e.keyCode === 16 ) {
                return
            }*/

            barcodeScan += e.key;

            setTimeout(() => {

                barcodeScan = "";

            }, 500);
        }

        if (scanActive === true) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    })

    const barcodeScanner = () => {

        if (scanActive) {
            setScanActive(false);
        } else {
            setScanActive(true);
        }
    }

    const showProduct = (product) => {

        setProduct(product)
        setShowProductModal(true)
    }

    async function getProduct(id, {signal} = {}) {
        setLoading(true)


        return window.axios.get(`/products/${id}`, {signal})
            .then(response => {

                if (response && response.data) {
                    setProduct(response.data.data)
                }
                return response
            })
            .catch((error) => {
                console.log(error.toJSON());
                showError('Došlo je do greške!')
            })
            .finally(() => setLoading(false))
    }


    const runSearch = (value) => {
        console.log("serach: " + value)
        setSearch(value)
    }

    const fetchData = async () => {
        setProducts([])
        setIsLoading(true)
        let fetchUrl = `/products?page=${currentPage}&search=${search}`;
        const result = await window.axios.get(fetchUrl)

        setProducts(result.data.data)
        setMeta(result.data.meta)
        setLinks(result.data.links)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        // setProducts(result.data)
    }, [search, currentPage])

    useEffect(() => {
        updateChange()

    }, [cash, cartItems])

    const playSound = (src) => {
        let sound = new Audio();
        sound.src = src;
        sound.play();
        sound.onended = () => sound = null;
    }


    const beep = () => {
        playSound(beepSound);
    }

    const clearSound = () => {
        playSound(beepButton);
    }

    const calcDiscount = (value, type) => {
        value = parseFloat(value)
        if (isNaN(value)) {
            console.error('Neispravni ulazni podaci.');
            return [];
        }
        let total = parseFloat(getTotalPrice());

        if (type === "percent") {

            if (value > 100) {
                value = 100;
            }

            setDiscountPercent(value)

            if (cartItems.length) {

                let amount = total * (value / 100)
                amount = (Math.round(amount * 100) / 100).toFixed(2);
                setDiscountAmount(amount)
            }


        } else {

            if (value > total) {
                // value = total;
                // setDiscountPercent(100)
                // setDiscountAmount(value)
            } else {
                let amount = (value / total) * 100

                amount = (Math.round(amount * 100) / 100).toFixed(2);
                setDiscountAmount(value)
                setDiscountPercent(amount)
            }


        }
    }
    const getTotalPrice = (discount = false) => {

        const totalAmount = cartItems.reduce(
            (total, item) => total + (item['quantity'] * (
                item['use_override_price'] === true ? item['override_price_with_tax'] : item['price_with_tax'] || 0)), 0
        )
        if (discount && discountActive) {
            return (totalAmount - discountAmount).toFixed(2);
        }

        return totalAmount.toFixed(2)
    }

    const getCartItems = () => {

        return cartItems.map(({
                                  id,
                                  product_attribute_id,
                                  code,
                                  quantity,
                                  name,
                                  product_attribute_name,
                                  price,
                                  price_tax,
                                  price_with_tax,
                                  tax_rate,
                                  override_price_with_tax,
                                  override_price_with_tax_f,
                                  use_override_price,
                                  reduction_price,
                                  reduction_price_tax,
                                  reduction_price_with_tax
                              }) => ({
            id,
            product_attribute_id,
            code,
            quantity,
            name,
            product_attribute_name,
            price,
            price_tax,
            price_with_tax,
            tax_rate,
            override_price_with_tax,
            override_price_with_tax_f,
            use_override_price,
            reduction_price,
            reduction_price_tax,
            reduction_price_with_tax
        }));

    }

    const addToCart = (product, useReductionPrice) => {


        if (useReductionPrice) {
            product.use_override_price = true
            product.override_price_with_tax = product.reduction_price_with_tax;
            product.override_price_with_tax_f = priceFormat(product.reduction_price_with_tax);
        } else {
            product.use_override_price = false
        }

        const exist = cartItems.find((item) => item.code === product.code)

        if (product.stock_quantity < (exist ? (exist.quantity + 1) : 1)) {
            showError('Nedovoljna količina proizvoda na skladištu!')
            return;
        }

        if (exist) {
            setCartItems(
                cartItems.map((item) =>
                    item.code === product.code ? {...exist, quantity: exist.quantity + 1} : item
                )
            )
        } else {
            setCartItems([...cartItems, {...product, quantity: 1}])
        }
        removeDiscount()
        beep();
    }

    const addToCartReturn = (product, price) => {

        price = price.replace(',', '.');

        if (product.price_with_tax !== parseFloat(price)) {
            product.use_override_price = true
            product.override_price_with_tax = parseFloat(price);
            product.override_price_with_tax_f = priceFormat(parseFloat(price));
        } else {
            product.use_override_price = false
        }

        console.log(product)

        const exist = cartItems.find((item) => item.code === product.code)

        if (exist) {
            setCartItems(
                cartItems.map((item) =>
                    item.code === product.code ? {...exist, quantity: exist.quantity - 1} : item
                )
            )
        } else {
            setCartItems([...cartItems, {...product, quantity: -1}])
        }
        removeDiscount()
        beep();
    }
    const removeDiscount = () => {

        setDiscountAmount(0.00);
        setDiscountPercent(0)
        setDiscountActive(false)

    }

    const removeFromCart = (product) => {
        const exist = cartItems.find((item) => item.code === product.code)
        if (exist.quantity === 1) {
            setCartItems(cartItems.filter((item) => item.code !== product.code))
            clearSound()
        } else {
            setCartItems(
                cartItems.map((item) =>
                    item.code === product.code ? {...exist, quantity: exist.quantity - 1} : item
                )
            )
            beep()
        }
    }

    const updateChange = () => {

        setChange(parseFloat(cash) - getTotalPrice(true))
    }

    const addCash = (amount) => {
        setCash(parseFloat(cash) + parseFloat(amount))
        beep()
    }

    const inputCash = (amount) => {
        setCash(amount.toString().replace(',', '.'))
        beep()
    }

    const clearCart = () => {
        setCartItems([])
        clearSound()
    }

    const finish = () => {
        const time = new Date();
        //console.log(time)
        updateChange()
        setShowInvoiceSettingsModal(true)
    }
    const submit = () => {
        const time = new Date();
        setShowReceiptModal(true)
        setReceipt({
            receiptNo: `ACPOS-KS-${Math.round(time.getTime() / 1000)}`,
            receiptDate: dateFormat(time)
        })
    }

    const clearAll = () => {
        setShowReceiptModal(false)
        setShowInvoiceSettingsModal(false)
        setCartItems([])
        setReceipt({})
        setCash(0)
        setChange(0)
        setPaymentMethod('G')
        setInvoiceType('G')
        setPrintType('T')
        setInvoiceCompany({
            company: '',
            oib: '',
            address: '',
            zip: '',
            city: '',
            country: 'Hrvatska'
        })
        removeDiscount()
    }

    function changePage(pageNumber) {
        setCurrentPage(pageNumber)
        //fetchData()
    }

    return (
        <>
            <HeaderTop displaySearch={true} runSearch={runSearch} scanActive={scanActive}
                       barcodeScanner={barcodeScanner}/>

            <div className=" w-full h-full flex flex-column " style={{inHeight: `calc(100vh - 80px)`,}}>
                <div className="w-6/12 flex-grow flex">
                    <div className="flex flex-col bg-blue-gray-50 h-fit w-full">
                        <div className="h-full overflow-hidden mt-4">
                            {mode == "return" &&
                            <div className="d-flex  py-3 px-4 "><span className="text-xl font-semibold text-red-500">Povrat proizvoda</span>
                            </div>}
                            <div className="h-full overflow-y-auto px-2">

                                {(products.length === 0 && isLoading === false) &&
                                <NoResults/>
                                }

                                {(products.length === 0 && isLoading === true) &&

                                <div className="grid grid-cols-5 gap-4 pb-1">
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                            <ProductLoading key={item} className="w-full"/>
                                        ))
                                    }
                                </div>

                                }
                                {(products.length === 0) &&
                                <div></div>
                                }
                                <div className="grid grid-cols-5 gap-5 pb-3">
                                    {products.map(item => (
                                        <ProductBox key={'product-box-' + item.code} item={item}
                                                    showProduct={showProduct}/>
                                    ))}
                                </div>

                            </div>
                        </div>

                        {(products) &&

                        <Pagination meta={meta} changePage={changePage} currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}/>
                        }

                    </div>
                </div>
                <RightSidebar
                    mode={mode}
                    clearCart={clearCart}
                    removeFromCart={removeFromCart}
                    addToCart={addToCart}
                    cartItems={cartItems}
                    getTotalPrice={getTotalPrice}
                    finish={finish}
                    discountAmount={discountAmount}
                    discountPercent={discountPercent}
                    discountActive={discountActive}
                    setDiscountActive={setDiscountActive}
                    calcDiscount={calcDiscount}
                    setShowInvoiceTypeModal={setShowInvoiceTypeModal}
                />
            </div>
            <InvoiceSettingsModal
                showInvoiceSettingsModal={showInvoiceSettingsModal}
                setShowInvoiceSettingsModal={setShowInvoiceSettingsModal}
                setShowReceiptModal={setShowReceiptModal}
                receipt={receipt}
                cartItems={cartItems}
                getTotalPrice={getTotalPrice}
                addCash={addCash}
                inputCash={inputCash}
                mode={mode}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                printType={printType}
                setPrintType={setPrintType}
                cash={cash}
                change={change}
                clearAll={clearAll}
                submit={submit}
                showReceiptModal={showReceiptModal}
                getCartItems={getCartItems}
                discountAmount={discountAmount}
                invoiceType={invoiceType}
                invoiceCompany={invoiceCompany}
            />
            <InvoiceTypeModal
                setShowInvoiceTypeModal={setShowInvoiceTypeModal}
                showInvoiceTypeModal={showInvoiceTypeModal}
                setInvoiceType={setInvoiceType}
                invoiceType={invoiceType}
                setInvoiceCompany={setInvoiceCompany}
                invoiceCompany={invoiceCompany}
                printType={printType}
                setPrintType={setPrintType}
            />

            <ProductModal
                mode={mode}
                addToCart={addToCart}
                addToCartReturn={addToCartReturn}
                showProductModal={showProductModal}
                setShowProductModal={setShowProductModal}
                product={product}
                setProduct={setProduct}
            />

            <ReceiptModal
                showReceiptModal={showReceiptModal}
                setShowReceiptModal={setShowReceiptModal}
                receipt={receipt}
                cartItems={cartItems}
                getCartItems={getCartItems}
                getTotalPrice={getTotalPrice}
                paymentMethod={paymentMethod}
                discountAmount={discountAmount}
                cash={cash}
                change={change}
                clearAll={clearAll}
            />
        </>

    )
}

export default ProductMode;