import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import user from "../Models/user"
import axios from "axios";

export default function Profile() {
    //const [user, setUser] = useState([])

    const navigate = useNavigate()

   /* useEffect(() => {
        axios.get('user')
            .then(response => setUser(response.data));
    }, [])*/

    const afterUserDestroyed = () => {
        navigate('/login')
    }

    function logout() {
        axios.post('logout').finally(() => {
            localStorage.removeItem('token')
            axios.defaults.headers.common['Authorization'] = 'Bearer'
            user.logout(afterUserDestroyed)
            //navigate('/login')
        })
    }

    return (
        <div>
            <div>ID: {user.id}</div>
            <div>Email: {user.email}</div>
            <div>
                <button type="button" onClick={logout}>Logout</button>
            </div>
        </div>
    );
}