import React, {useState, useEffect, useRef} from 'react'

import {priceFormat, numberFormat, processAxiosError, showSuccess, showError} from '../utils/helper'
import {printReceipt} from '../Helpers/receipt'
import AsyncSelect from 'react-select/async';

import logo from '../assets/img/logo.png'

const InvoiceTypeModal = (props) => {

    const {setShowInvoiceTypeModal,showInvoiceTypeModal, setInvoiceType, invoiceType, invoiceCompany, setInvoiceCompany, setPrintType} = props;
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    const [company, setCompany] = useState(invoiceCompany.company)
    const [oib, setOib] = useState(invoiceCompany.oib)
    const [address, setAddress] = useState(invoiceCompany.address)
    const [zip, setZip] = useState(invoiceCompany.zip)
    const [city, setCity] = useState(invoiceCompany.city)
    const [country, setCountry] = useState(invoiceCompany.country)

  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };
  // handle selection
  const handleChange = value => {


    setSelectedValue(value);

    if(value === null) {

        clear()
        setValue('');

        return;
    }

    setCompany(value.company)
    setOib(value.oib)
    setAddress(value.address)
    setZip(value.zip)
    setCity(value.country)
    setCountry(value.country)
  }
  // load options using API call
  const loadOptions = (inputValue) => {

        return window.axios.get(`/customers?search=${inputValue}`)
            .then(response => {
                console.log(response.data.data);
                return response.data.data;

            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
  };

    const clear = () => {

        setCompany('')
        setOib('')
        setAddress('')
        setZip('')
        setCity('')
        setCountry('Hrvatska')
        setInvoiceCompany({
            company: '',
            oib: '',
            address: '',
            zip: '',
            city: '',
            country: 'Hrvatska'
        })
        setPrintType('T')
        setValue('');
        setSelectedValue('');
    }


    const changeInvoiceType = (value) => {

        setInvoiceType(value)

        if(value === 'G') {
            clear()
            setPrintType('T')
        } else {
            setPrintType('P')
        }
    }

    const saveInvoiceType = () => {

        setInvoiceCompany({
            company: company,
            oib: oib,
            address: address,
            zip: zip,
            city: city,
            country: country
        })
        setPrintType('T')
        /*if(invoiceType === 'G') {
            setPrintType('T')
            console.log("T");
        } else {
            setPrintType('P')
            console.log("P");
        }*/

        setShowInvoiceTypeModal(false)
    }

    useEffect(() => {
        clear()

    }, [invoiceType])

    return (
        <>
            { showInvoiceTypeModal &&
            <>
                <div className="hide-print fixed w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center p-24">
                    <div onClick={() => setShowInvoiceTypeModal(false)} className="fixed glass w-full h-screen left-0 top-0 z-0 opacity-100"></div>
                    <div className="w-96 rounded-sm bg-white shadow-xl overflow-hidden z-10 opacity-100 scale-100">
            <div className="text-left w-full text-sm p-6 overflow-auto">
                <div className="text-center">
                    <img src={logo} className="w-10 m-auto filter grayscale" />
                    <h2 className="text-xl font-semibold">Maskice.hr</h2>
                    <p>Odaberite vrstu računa</p>
                </div>
                <div className="flex mt-2 text-xs">
                        <div className="relative w-full max-w-full mx-auto mt-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <select
                              value={invoiceType}
                              onChange={e => changeInvoiceType(e.target.value)}
                              className="w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2">
                             <option key={'G'} value={'G'}>G</option>
                              <option key={'R1'} value={'R1'}>R1</option>
                          </select>
                        </div>
                    </div>
                {invoiceType === 'R1'  &&
                (<div className="mb-3">
                    <hr className="my-2" />
      <AsyncSelect
        cacheOptions
        defaultOptions
        value={selectedValue}
        getOptionLabel={e => e.label}
        noOptionsMessage={() => 'Nema rezultata'}
        loadingMessage={() => 'Pretraživanje...'}
        placeholder={'Pretraži...'}
        getOptionValue={e => e.id}
        isClearable={true}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
      />

                <hr className="my-2" />
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Tvrtka
                  </label>
                  <input
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    OIB
                  </label>
                  <input
                      value={oib}
                      onChange={(e) => setOib(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Adresa
                  </label>
                  <input
                      value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Grad
                  </label>
                  <input
                      value={city}
                        onChange={(e) => setCity(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Poštanski broj
                  </label>
                  <input
                      value={zip}
                    onChange={(e) => setZip(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
                <div className="mb-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" >
                    Država
                  </label>
                  <input
                      value={country}
                    onChange={(e) => setCountry(e.target.value)}
                      className="shadow1 appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 "
                      type="text"
                      placeholder=""/>
                </div>
            </div>)}
                <hr className="my-2" />

          <div className="select-none h-auto w-full text-center pt-3 pb-4">

                <button onClick={() => saveInvoiceType()} className={" font-bold text-base rounded-sm w-full py-3 focus:outline-none bg-primary hover:bg-yellow-600 text-black"}>
                    {'Spremi'}
                </button>

          </div>

                <div>

                </div>
            </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default InvoiceTypeModal
