import React, { useState } from 'react'

import {priceFormat, processAxiosError, showSuccess, showError} from '../utils/helper'
import {printReceipt} from '../Helpers/receipt'
import logo from '../assets/img/logo.png'
import {getPdf} from "../Helpers/pdf";
import user from "../Models/user"
import {useNavigate} from "react-router-dom";
const InvoiceSettingsModal = (props) => {

    const { mode,printType, setPrintType, invoiceType, invoiceCompany, paymentMethod, setPaymentMethod, cartItems, getCartItems, getTotalPrice,discountAmount, clearAll, addCash, cash, inputCash, change, showInvoiceSettingsModal,setShowInvoiceSettingsModal } = props;
    const [processing, setProcessing] = useState(false)
    const moneys = [0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200]
    const navigate = useNavigate()
    const logout = async () => {
        //if(confirm('Odjava?')) {
            await window.axios.post('/logout')
            .finally(() => {
            localStorage.removeItem('token')
            //localStorage.removeItem('invoiceDevice')
            user.logout({})

            window.axios.defaults.headers.common['Authorization'] = 'Bearer'
            navigate('/login')
        })
        //}
    }

    const printAndProceed = async () => {
        setProcessing(true)
        // Store to database
        await window.axios.post('/sales', {
            payment_method: paymentMethod,
            type: invoiceType,
            print: printType,
            company: invoiceCompany,
            total: getTotalPrice(),
            total_with_discount: getTotalPrice(true),
            discount: discountAmount,
            cash: cash,
            change: change,
            mode: mode,
            products: getCartItems()
        })
        .then((response) => {
            setProcessing(false)

            //console.log(response)
            if(response.status === 201 && response.data.success === true) {
                //console.log(response.data.data)

                showSuccess('Račun uspješno izdan!')
                if( printType === "P") {
                    getPdf(response.data.data.invoice_pdf)

                } else {
                    printReceipt(response.data.data)

                }
                clearAll()
                //logout()
                //setShowReceiptModal(false)
            } else if(response.status === 200 && response.data.success === false) {
                console.log(response.data)
                showError(response.data.error)

            } else{
                showError('Došlo je do greške! Error: ' + response.status)
            }
        })
        .catch((error) => {
            setProcessing(false)
            processAxiosError(error)
        })
    }


    return (
        <>
            { showInvoiceSettingsModal &&
            <>
                <div className="hide-print fixed w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center p-24">
                    <div onClick={() => setShowInvoiceSettingsModal(false)} className="fixed glass w-full h-screen left-0 top-0 z-0 opacity-100"></div>
                    <div className="w-96 rounded-sm bg-white shadow-xl overflow-hidden z-10 opacity-100 scale-100">
            <div className="text-left w-full text-sm p-6 overflow-auto">
                <div className="text-center">
                    <img src={logo} alt="Blagajna" className="w-10 m-auto filter grayscale" />
                    <h2 className="text-xl font-semibold">Maskice.hr</h2>
                    <p>Odaberite vrstu plaćanja</p>
                </div>
                <div className="flex mt-2 text-xs">

                        <div className="relative w-full max-w-full mx-auto mt-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <select
                              value={paymentMethod}
                              onChange={e => setPaymentMethod(e.target.value)}
                              className="w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2">
                             <option key={'G'} value={'G'}>Novčanice</option>
                              <option key={'K'} value={'K'}>Kartice</option>
{/*                              <option key={'T'} value={'T'}>Transakcijski račun</option>
                              <option key={'C'} value={'C'}>Čekovi</option>
                              <option key={'O'} value={'O'}>Ostalo</option>*/}
                          </select>
                        </div>
                    </div>

                <hr className="my-2" />

          <div className="select-none h-auto w-full text-center pt-3 pb-4">
            <div className="flex mb-3 text-lg font-semibold text-blue-gray-700">
              <div>UKUPNO</div>
              <div className="text-right w-full">
                {cartItems.length === 0
                  ? priceFormat(0)
                  : priceFormat(getTotalPrice(true))
                }
              </div>
            </div>
                {paymentMethod === 'G' && mode === 'sale' &&
            <div className="mb-3 text-blue-gray-700 px-3 pt-2 pb-3 rounded-lg bg-blue-gray-50">
              <div className="flex text-lg font-semibold">
                <div className="flex-grow text-left">NOVČANICE</div>
                <div className="flex text-right">
                  <div className="mr-2">
                    <button  className="text-blue-gray-300 hover:text-pink-500 focus:outline-none" onClick={e => inputCash(0)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                    </div>
                  <input onInput={e => inputCash(e.target.value)} value={cash} type="text" className="w-28 text-right bg-white shadow rounded-lg focus:bg-white focus:shadow-lg px-2 focus:outline-none" />
                </div>
              </div>
              <hr className="my-2" />
              <div className="grid grid-cols-3 gap-2 mt-2">
                  <button onClick={() => addCash(getTotalPrice(true))} key='modeltotal' className="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span> { getTotalPrice(true) }€ </span></button>
                {moneys.map((money) => (
                  <button onClick={() => addCash(money)} key={money} className="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span> { money }€ </span></button>
                ))}


              </div>
            </div>}
           {paymentMethod === 'G'  && mode === 'sale' &&
            <div className={"flex mb-3 text-lg font-semibold text-blue-gray-700 rounded-lg py-2 px-3 "+( change >= 0 ? "bg-cyan-50" : "bg-pink-100")}>
              {change >= 0 && <div className="text-cyan-800">VRATITI</div> }
              <div className="text-right flex-grow">
                <span className={change >= 0 ? 'text-cyan-600' : 'text-pink-600'}>{ priceFormat(change) }</span>
              </div>
            </div>}

                        <div className="relative w-full max-w-full mx-auto mt-5 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <select
                              value={printType}
                              onChange={e => setPrintType(e.target.value)}
                              className="w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2">
                             <option key={'T'} value={'T'}>POS printer</option>
                              <option key={'P'} value={'P'}>A4 Printer</option>
                          </select>
                        </div>





            {paymentMethod === 'G' && mode === 'sale' ? (<button onClick={() => printAndProceed()} disabled={processing || change < 0 || cartItems.length <= 0} className={" font-bold text-base rounded-sm w-full py-3 focus:outline-none "+(change >= 0 && cartItems.length > 0 ? "bg-primary hover:bg-yellow-600 text-black" : "bg-secondary text-gray-500")}>
                    { processing ? 'Procesuiranje..' : 'ZAVRŠI'}
                </button>) : (<button onClick={() => printAndProceed()} disabled={processing || cartItems.length <= 0} className={" font-bold text-base rounded-sm w-full py-3 focus:outline-none bg-primary hover:bg-yellow-600 text-black"}>
                    { processing ? 'Procesuiranje..' : 'ZAVRŠI'}
                </button>)}

          </div>

                <div>

                </div>
            </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default InvoiceSettingsModal
