export default function ProductBox({ item, showProduct }) {
  return (
    <div onClick={() => showProduct(item)} key={item.code} role="button"
         className="bg-white rounded-md border-1 border-solid border-gray-200 group relative">
            <span className={(item.stock_quantity > 0 ? "text-black bg-green-400" : " text-white bg-red-500 ") + " absolute text-xs font-semibold inline-block py-1 px-3 uppercase rounded-tl-md  rounded-br-md uppercase last:mr-0 mr-1"}>
              {item.stock_quantity}
            </span>
        <div
            className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-white lg:aspect-none group-hover:opacity-75 lg:h-60 p-4">
            <img src={item.picture_url} alt={item.name}
                 className="h-full w-full object-cover object-center lg:h-full lg:w-full"/>
        </div>
        <div className="mt-2 flex-column px-3">
            <p className="flex-grow mr-1  product-code">{item.code}</p>
            <p className="flex-grow mr-1  product-name">{item.name}</p>
            <p className="flex-grow mr-1  product-combination-name">{item.product_attribute_name}</p>
        </div>
        <div className="mt-2 flex justify-between px-3 mb-2 border-t-1">
            <p className="flex-grow truncate mr-1 product-price-old">
                {item.reduction && <span>{item.reduction_price_with_tax_f} {item.currency}</span>}
                </p>
            <p className="nowrap product-price">{item.price_with_tax_f} {item.currency}</p>
        </div>
    </div>
  );
}

