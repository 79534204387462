import global from '../global';
import {processAxiosError, showError, showSuccess} from "../utils/helper";

export const  getPdf = async (url) => {
    console.log("pdf: " + url)
    // Store to database
    await window.axios.post(url, {
        responseType: 'blob'
    })
    .then((response) => {
        //console.log(response.data)

       if(response.status === 200) {


          const binaryContent = atob(response.data.pdf);

          const uint8Array = new Uint8Array(binaryContent.length);
          for (let i = 0; i < binaryContent.length; i++) {
            uint8Array[i] = binaryContent.charCodeAt(i);
          }

            const blob = new Blob([uint8Array], { type: "application/pdf" });

            const blobURL= window.URL.createObjectURL(blob);
            console.log(blobURL)
            var iframe =  document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = blobURL;
            iframe.onload = function() {
              setTimeout(function() {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };

            showSuccess('PDF print!')
        }else{
            showError('Došlo je do greške! Error: ' + response.status)
        }
    })
    .catch((error) => {
        processAxiosError(error)
    })
}