import React, {Component} from 'react';
import axios from 'axios';
import SelectFilter from "./Filters/SelectFilter";
import DateFilter from "./Filters/DateFilter";
import TextFilter from "./Filters/TextFilter";
import {
    CheckCircleIcon,
    MinusCircleIcon,
    XCircleIcon,

} from "@heroicons/react/24/outline";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from "@heroicons/react/20/solid";

import {
    Col,
    Row,
    Nav,
    Card,
    Form,
    Image,
    Button,
    Table,
    Dropdown,
    ProgressBar,
    Pagination,
    Tooltip,
    FormCheck,
    ButtonGroup,
    OverlayTrigger
} from 'react-bootstrap';


import {Link, useNavigate} from 'react-router-dom';
import {route} from "../routes";


export default class DataTableTailwind extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            entities: {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    per_page: 10,
                    to: 1,
                    total: 1,
                },
            },
            filters: {},
            actions: {},
            bulkActions: false,
            bulkAllChecked: false,
            bulkChecked: [],
            bulkAction: false,
            first_page: 1,
            current_page: 1,
            sorted_column: this.props.sorted_column ?? this.props.columns[0].name,
            offset: 4,
            order: 'desc',
            loading: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(
            this
        );
    }


  bulkActionChange = (e) => {
    const newOption = e.target.value;
    console.log(newOption);
  }

 /* applyBulkAction = () => {
    if (bulkOption === "delete_user") console.log("a");
  }*/

    handleChange = () => {
        var bulkAllChecked = !this.state.bulkAllChecked;
        this.setState({bulkAllChecked: bulkAllChecked});
        var checkedCopy = [];

        if (bulkAllChecked === true) {
            this.state.entities.data.forEach(function (e, index) {
                checkedCopy.push(e.id);
            });
        }

        this.setState({
            bulkChecked: checkedCopy
        });
    };
    handleSingleCheckboxChange = id => {
        console.log(id);

        let checkedCopy = this.state.bulkChecked;

        const index = checkedCopy.indexOf(id);

        if (index > -1) {
            checkedCopy.splice(index, 1);
        } else {
            checkedCopy.push(id);
        }

        this.setState({
            bulkChecked: checkedCopy
        });
    };
    applyFilters = (filter, e) => {

        let filters = this.state.filters;

        if (Array.isArray(e)) {
            filters[filter] = [];

            e.map((item) => {
                filters[filter].push(item.value);
            });

        } else if (typeof e === 'object') {
            filters[filter] = e ? e.value : null;
        } else {
            filters[filter] = e;
        }


        this.setState({filters: filters}, () => {
            this.fetchEntities()
        });
    }


    fetchEntities() {
        let fetchUrl = `${this.props.url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.state.entities.meta.per_page}`;
        this.setState({loading: true});
        axios.get(fetchUrl, {
            params: this.state.filters,
        })
            .then(response => {
                this.setState({entities: response.data, loading: false});
            })
            .catch(e => {
                console.error(e);
            });
    }

    destroyEntity(route, id) {

        return axios.delete(`${route}/${id}`).then(() => {
                this.setState(prevState => ({
                    entities: {
                        data: prevState.entities.data.filter(el => el.id !== id),
                        meta: prevState.entities.meta
                    }
                }))
            }
        ).catch(error => {
            console.log(error);
        });
    }

    changePage(pageNumber) {
        this.setState({current_page: pageNumber}, () => {
            this.fetchEntities()
        });
    }

    pagesNumbers() {
        if (!this.state.entities.meta.to) {
            return [];
        }
        let from = this.state.entities.meta.current_page - this.state.offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + (this.state.offset * 2);
        if (to >= this.state.entities.meta.last_page) {
            to = this.state.entities.meta.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }

    componentDidMount() {
        this.setState({current_page: this.state.entities.meta.current_page}, () => {
            this.fetchEntities()
        });
    }

    tableHeader() {
        let icon;
        if (this.state.order === 'asc') {
            icon = <ChevronUpIcon className="inline h-5 w-5 stroke-orange-400"/>;
        } else {
            icon = <ChevronDownIcon className="inline h-5 w-5 stroke-orange-400"/>;
        }

        let header = '';

        if (this.props.bulkActions) {
            header = [header, (<th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase " key="bulk">
                <Form.Check type="checkbox" aria-label="Select All" id={'bulk-select-all'} onChange={this.handleChange}
                            checked={this.state.bulkAllChecked}/></th>)];
        }

        if (this.props.actions.length) {

        }

        header = [header, this.props.columns.map((column, index, item) => {

            let thClassName = "px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ";

            if (index === 0) {
                thClassName = this.props.bulkActions ? "" : "px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"


            } else if (index === (this.props.actions.length ? item.length : item.length - 1)) {
                thClassName = " px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase";
            }

            return <th className={thClassName} key={column.name} onClick={() => this.sortByColumn(column.name)}>
                {column.label}
                {column.name === this.state.sorted_column && icon}
            </th>
        })];

        if (this.props.actions.length) {
            header = [header, (<th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase " key="actions"></th>)];
        }

        return header;
    }

    actionsColumn(item) {

        if (this.props.actions.length) {
            return <td key="edit{item.id}">{this.actionsList(item)}</td>
        }

    }

    bulkActionsColumn(item) {
        if (this.props.bulkActions) {
            return <td key="bulk{item.id}"><Form.Check type="checkbox"
                                                       checked={this.state.bulkChecked.includes(item.id)}
                                                       value={item.id} name="bulk-ids" aria-label="Select All"
                                                       id={'bulk-select-' + item.id}
                                                       onChange={() => this.handleSingleCheckboxChange(item.id)}/></td>
        }

    }

    tableBody() {
        if (this.state.loading) {
            return <tr>
                <th colSpan={this.props.columns.length + 1} className="text-center ">
                    <div className="w-100 d-flex flex-column justify-content-center py-7">
                        <strong>Učitavanje...</strong>
                        <div className="mt-2">
                            <div className="spinner-border" role="status" aria-hidden="true"></div>
                        </div>
                    </div>
                </th>
            </tr>
        }

        if (this.state.entities.data.length) {
            return this.state.entities.data.map((item, key) => {

                return <tr className="" key={item.id}>
                    {this.bulkActionsColumn(item)}
                    {
                        this.props.columns.map((column, index) => {
                            if (column.type === 'status') {
                                const VerifiedIcon = item[column.name] == 1 ? CheckCircleIcon : MinusCircleIcon;
                                return (<td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap" key={index}>
                                    <VerifiedIcon
                                        className={`icon icon-xxs text-${item[column.name] == 1 ? 'success' : 'gray'} me-1`}/>
                                </td>);
                            }
                            if (column.formater) {
                                return (<td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap" key={index}>
                                    {column.formater(item[column.name], item, column.name)}
                                </td>);
                            }
                            return (
                                <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap" key={index}>
                                    {item[column.name]}
                                </td>
                            )
                        })}
                    {this.actionsColumn(item)}
                </tr>
            })

        } else {
            return <tr>
                <th colSpan={this.props.columns.length + 1} className="text-center ">
                    <div className="w-100 d-flex flex-column justify-content-center py-7">
                        <strong>Nema rezultata.</strong>
                    </div>
                </th>
            </tr>
        }
    }

    sortByColumn(column) {
        if (column === this.state.sorted_column) {
            this.state.order === 'asc' ? this.setState({order: 'desc', current_page: this.state.first_page}, () => {
                this.fetchEntities()
            }) : this.setState({order: 'asc'}, () => {
                this.fetchEntities()
            });
        } else {
            this.setState({sorted_column: column, order: 'asc', current_page: this.state.first_page}, () => {
                this.fetchEntities()
            });
        }
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return <li className={page === this.state.entities.meta.current_page ? 'relative z-10 inline-flex items-center bg-primary text-sm font-semibold text-black focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                       key={page}>
                <button className="px-4 py-2" onClick={() => this.changePage(page)}>{page}</button>
            </li>
        })
    }


    filtersList() {
        return this.props.filters.map(filter => {
            if (filter.type === "date") {
                return <div key={filter.name} className="col-lg-3">
                    <DateFilter data={filter} applyFilters={this.applyFilters}/>
                </div>
            } else if (filter.type === "select") {
                return <div key={filter.name} className="col-lg-3">
                    <SelectFilter data={filter} applyFilters={this.applyFilters}/>
                </div>
            } else if (filter.type === "text") {
                return <div key={filter.name} className="col-lg-3">
                    <TextFilter data={filter} applyFilters={this.applyFilters}/>
                </div>
            }
        })
    }

    actionsList(item) {
        return this.props.actions.map((action, i) => {
            return this.actionsItem(action, item.id, i);
        })
    }

    actionsItem(action, id, i) {

        /*if (action.name === "edit") {
            return (<OverlayTrigger key={i} placement="top" overlay={<Tooltip className="m-0">{action.label}</Tooltip>}>
                <Link className="ms-2" to={route(action.route, {id: id})}>
                    <PencilAltIcon className="icon icon-xs text-info"/>
                </Link>
            </OverlayTrigger>);
        } else */


        if (action.name === "delete") {
            return (<OverlayTrigger key={i} placement="top" overlay={<Tooltip className="m-0">{action.label}</Tooltip>}>
                <a className="ms-2" onClick={async () => {
                    await this.destroyEntity(action.route, id)
                    //await this.fetchEntities()
                }}>
                    <XCircleIcon className="icon icon-xs text-danger"/>
                </a>
            </OverlayTrigger>);
        }

    }

    render() {
        return (
            <div className="relative overflow-x-auto">

                    <div className="row mb-2">
                        {this.filtersList()}
                    </div>
                    <div className="data-table overflow-hidden border rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr className="">
                                {this.tableHeader()}
                            </tr>
                            </thead>
                            <tbody  className="divide-y divide-gray-200">
                            {this.tableBody()}
                            </tbody>
                        </table>
                        {(this.state.entities.data && this.state.entities.data.length > 0) &&


                        <div
                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-md mx-2 mb-4">
                            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                <div>
                                    <p className="text-sm text-gray-700">
                                        Prikazuje od <span
                                        className="font-medium">{this.state.entities.meta.from}</span> do <span
                                        className="font-medium">{this.state.entities.meta.to}</span> od{' '}
                                        <span className="font-medium">{this.state.entities.meta.total}</span> rezultata
                                    </p>
                                </div>
                                <div>


                                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                         aria-label="Pagination">
                                        <button
                                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                            disabled={1 === this.state.entities.meta.current_page}
                                            onClick={() => this.changePage(this.state.entities.meta.current_page - 1)}
                                        >
                                            <span className="sr-only"> Prethodna</span>
                                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                        {this.pageList()}
                                        <button
                                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                            disabled={this.state.entities.meta.last_page === this.state.entities.meta.current_page}
                                            onClick={() => this.changePage(this.state.entities.meta.current_page + 1)}
                                        >

                                            <span className="sr-only">Sljedeća</span>
                                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>


                                    </nav>


                                </div>
                            </div>
                        </div>

                        }
                    </div>

            </div>
        );
    }
}