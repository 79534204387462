import React, { Component } from 'react';
import axios from 'axios';
import Select from "react-select";
import {Form} from 'react-bootstrap';

export default class SelectFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            options: this.props.data.options,
            customStyles: {

                control: (provided) => ({
                    ...provided,
                    borderRadius: '.5rem',
                }),

                multiValue: (provided) => ({
                    ...provided,
                    borderRadius: '.5rem'
                }),

                multiValueRemove: (provided) => ({
                    ...provided,
                    borderRadius: '.5rem'
                }),

                placeholder: (provided) => ({
                    ...provided,
                    borderRadius: '.5rem',
                }),

                singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = 'opacity 300ms';

                    return {
                        ...provided,
                        opacity,
                        transition,
                    };
                }
            },
        }
    }

    componentDidMount() {

        if(this.props.data.optionsUrl) {
            axios.get(this.props.data.optionsUrl)
                .then(response => {
                    const results = [];
                    //console.log(response.data.data);
                    Object.keys(response.data.data).forEach(function(key) {
                        results.push({
                            label: response.data.data[key],
                            value: key,
                        });
                    });

                    //results.push({label: 'Select ' + this.state.data.label, value: null});

                    this.setState({options: results});


                })
                .catch(e => {
                    console.error(e);
                });

        }

    }

    render() {
        return (
            <Form>
            <Form.Group className="mb-2">
                <Form.Label>{this.state.data.label}</Form.Label>
                <Select options={this.state.options} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                    }
                })} styles={this.state.customStyles} placeholder={"Odaberi..."} isClearable isMulti={this.state.data.multiple} onChange={(e) => this.props.applyFilters(this.state.data.name, e)}/>
            </Form.Group>
          </Form>
        )

    }
}