import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";

export default function Pagination({meta, changePage, currentPage}) {

    const offset = 4

    function pagesNumbers() {
        if (!meta.to) {
            return [];
        }
        let from = meta.current_page - offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + (offset * 2);
        if (to >= meta.last_page) {
            to = meta.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }

    const  pageList = () => {
        return pagesNumbers().map(page => {
            return <button  onClick={() => changePage(page)} className={page === currentPage ? 'relative z-10 inline-flex items-center bg-primary px-4 py-2 text-sm font-semibold text-black focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                       key={page}>
                {page}
            </button>
        })
    }


  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-md mx-2 mb-4">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Prikazuje od <span className="font-medium">{meta.from}</span> do <span className="font-medium">{meta.to}</span> od{' '}
            <span className="font-medium">{meta.total}</span> rezultata
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
               disabled={1 === meta.current_page}
               onClick={() => changePage(meta.current_page - 1)}
            >
                <span className="sr-only"> Prethodna</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageList()}
            <button className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
               disabled={meta.last_page === meta.current_page}
               onClick={() => changePage(meta.current_page + 1)}
            >

                  <span className="sr-only">Sljedeća</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
           </button>

          </nav>
        </div>
      </div>
    </div>
  );
}

