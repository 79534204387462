import {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import user from "../Models/user"
import axios from "axios";
import SalesIndex from "./SalesIndex";
import TableExample from "./TableExample";
import HeaderTop from "../Components/HeaderTop";


export default function Sales() {
    const [sales, setSales] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [meta, setMeta] = useState({})
    const [links, setLinks] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const offset = 4
    const navigate = useNavigate()

    useEffect(() => {
        //fetchData()
        // setProducts(result.data)
    }, [currentPage])




    return (        <>
        <HeaderTop title={'Računi'}/>
        <div className=" w-full h-full flex flex-column " style={{inHeight: `calc(100vh - 80px)`,}}>
        <div className="w-full flex-grow flex ">
            <div className="flex flex-col bg-blue-gray-50 h-full w-full">
                <div className="h-full overflow-hidden mt-4">
                    <div className="w-full flex flex-col bg-blue-gray-50 h-full bg-white pr-4 pl-2 mb-4">
                        <div className="bg-white rounded-sm flex flex-col h-full shadow px-4 py-4">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-4">Računi</h2>
                            <SalesIndex/>
                        </div>
                    </div>
                </div>
            </div>
        </div>        </div>
    </>

    );
}