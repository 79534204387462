import React from 'react'
import { Link, NavLink } from "react-router-dom";
import { FaBarcode, FaCalculator, FaClipboardList, FaCartArrowDown, FaDatabase, FaCog } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import user from "../Models/user"
import logo from '../assets/img/logo.png'
import { Tooltip } from 'react-tooltip'
import '../css/tooltip.css'


import api from "../utils/api";
import {
    ChartBarIcon,
    ClipboardDocumentListIcon,
    Cog6ToothIcon,
    Cog8ToothIcon,
    QueueListIcon,
    ShoppingCartIcon,
    ShieldCheckIcon,
    ChartBarSquareIcon,
    ArchiveBoxXMarkIcon
} from "@heroicons/react/20/solid";

const LeftSidebar =  (props) => {
    const { runSearch } = props;

    const navigate = useNavigate()

    const logout = async () => {
        //if(confirm('Odjava?')) {
            await window.axios.post('/logout')
            .finally(() => {
            localStorage.removeItem('token')
            //localStorage.removeItem('invoiceDevice')
            user.logout({})

            window.axios.defaults.headers.common['Authorization'] = 'Bearer'
            navigate('/login')
        })
        //}
    }



    return (
        <div className="w-30 bg-blue-gray-5 flex flex-row flex-shrink-0 pl-4 pr-2 py-4">
            <div className="flex flex-col items-center py-4 flex-shrink-0 w-20 bg-secondary rounded-sm">
                <a href="/"
                className="flex items-center justify-center h-12 w-12 bg-yellow-50 text-yellow-700 rounded-full">
                    <img src={logo} />
                </a>
                <Tooltip id="tooltip-sale" />
                <Tooltip id="tooltip-info" />
                <Tooltip id="tooltip-check" />
                <Tooltip id="tooltip-return" />
                <Tooltip id="tooltip-version" />
                <ul className="flex flex-col space-y-2 mt-12">
                    <li data-tooltip-id="tooltip-sale" data-tooltip-content="Prodaja" data-tooltip-place="right">
                        <NavLink to="/" className={'flex items-center'} >
                              {({ isActive}) => (

                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>
                                    <ShoppingCartIcon className="h-6 w-6" />

                                </span>

                              )}
                        </NavLink>
                    </li>
                    <li data-tooltip-id="tooltip-info" data-tooltip-content="Promet blagajne" data-tooltip-place="right">
                        <NavLink to="/info" className={'flex items-center'}>
                              {({ isActive}) => (

                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>
                                    <ChartBarIcon className="h-6 w-6 " />

                                </span>

                              )}
                        </NavLink>
                    </li>
                    <li  data-tooltip-id="tooltip-check" data-tooltip-content="Provjera blagajne" data-tooltip-place="right">
                        <NavLink to="/check" className={'flex items-center'}>
                              {({ isActive}) => (

                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>
                                    <ShieldCheckIcon className="h-6 w-6 " />

                                </span>

                              )}
                        </NavLink>
                    </li>
                    <li data-tooltip-id="tooltip-return" data-tooltip-content="Povrat artikla" data-tooltip-place="right">
                        <NavLink to="/return" className={'flex items-center'}>
                              {({ isActive}) => (

                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>
                                    <ArchiveBoxXMarkIcon className="h-6 w-6 " />

                                </span>

                              )}
                        </NavLink>
                    </li>
{/*                    <li>
                        <NavLink to="/sales" className={'flex items-center'}>
                              {({ isActive}) => (
                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>

                                    <ClipboardDocumentListIcon className="h-6 w-6" />
                                </span>
                              )}
                        </NavLink>
                    </li>*/}
{/*                    <li>
                        <NavLink to="/settings" className={'flex items-center'}>
                              {({ isActive}) => (
                                <span className={isActive ? "flex items-center justify-center h-12 w-12 rounded-sm bg-primary shadow-lg text-black" : "flex items-center justify-center h-12 w-12 rounded-sm text-white hover:text-black hover:bg-primary"}>
                                    <Cog8ToothIcon className="h-6 w-6" />
                                </span>
                              )}
                        </NavLink>
                    </li>*/}

                </ul>
                <ul className="flex flex-col space-y-2 mt-auto">
                    <li>
                        <div className="relative group inline-block">
                            <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2-translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto z-10">Odjava!</span>
                            <button
                            onClick={logout}
                            type="button"
                            className="flex items-center justify-center text-primary hover:bg-primary hover:text-black rounded-sm h-12 w-12 focus:outline-none"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </li>
                    <li className="flex items-center justify-center text-red-500" data-tooltip-id="tooltip-version" data-tooltip-content="Verzija blagajne" data-tooltip-place="right">
                        v{process.env.REACT_APP_POS_VERSION}
                    </li>
{/*                    <li>
                        <a
                        href="#"
                        target="_blank"
                        className="flex items-center justify-center text-primary hover:text-white h-12 w-12 focus:outline-none"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </li>*/}
                </ul>
            </div>
        </div>
    )
}

export default LeftSidebar
