import React from 'react';
import user from "../Models/user"
import errorImg from '../assets/img/error.jpg'

const ErrorPage = ({ error, resetErrorBoundary }) => {


  console.log('Error: ', error);

    const logout = async () => {

      await window.axios.post('/logout')
            .finally(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('invoiceDevice')
            user.logout({})

            window.axios.defaults.headers.common['Authorization'] = 'Bearer'
            window.location.href = '/'
        })

    }

  return (
      <>
          <div className="text-center py-5">
            <img src={errorImg} className="w-100 m-auto " alt="Error" width="400"/>
          </div>
          <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8  bg-blue-gray-50 h-full h-100">

              <div className="text-center">

                  <p className="text-base font-semibold text-red-600">ERROR</p>
                  <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Došlo je do greške</h1>

                  <p className="mt-6 text-base leading-7 text-red-600 text-md font-semibold">
                      {error.message}
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                      <button className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-primary-600 " onClick={resetErrorBoundary}>Osvježi stranicu</button>
                      <button className="rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-green-600 " onClick={logout}>Odjava</button>
                  </div>
              </div>
          </main>
      </>

  );
};

export default ErrorPage;