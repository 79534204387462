import { useState } from "react";
import { useNavigate } from 'react-router-dom'
import user from "../Models/user"
import api, {setApi} from "../utils/api";
import './pos.css';
import logo2 from '../assets/img/logo2.png'
import background from '../assets/img/background.png'

export default function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [errorMessage, seterrorMessage] = useState('')
        const [error, setError] = useState('')
const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    function handleSubmit(event) {
        event.preventDefault()

        function authenticatedCallback() {
            //let {from} = location.state || {from: {pathname: '/'}}
            //history.replace(from)
            navigate('/')
        }
        window.axios.post('/login', { username: username, password: password })
            .then(response => {
                console.log(response)
                localStorage.setItem('token', response.data.token)
                setApi()
                //api.defaults.headers.common["Authorization"] = "Bearer " + response.data.token
                user.authenticated(response.data.user, authenticatedCallback)
            })
            .catch(error => {
                console.log(error)
                /*if (error.response.status === 422) {
                    seterrorMessage(error.response.data.message)
                }*/
                setPassword('')
            })
            .finally()
    }

    return (
        <>
            {errorMessage && <div>{errorMessage}</div>}
            <div className="hide-print login-bg fixed w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center p-24 bg-cover" style={{backgroundImage: `url(${background})`}}>
                <div className="fixed glass w-full h-screen left-0 top-0 z-0 opacity-100"></div>
                <div className="w-96 rounded-sm bg-white shadow-xl overflow-hidden z-10 opacity-100 scale-100">
                    <div className="text-left w-full text-sm p-6 overflow-auto">
                        <div className="text-center mb-5">
                            <img src={logo2} className="w-60 m-auto" />
                            <h2 className="text-xl font-semibold mt-4 mb-4">Prijava</h2>
                        </div>
                        { error !== '' &&
                            <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5" role="alert">
                                <strong class="font-bold">Prijava nije uspješna! </strong>
                                <span class="block sm:inline">{ error }</span>
                                <span onClick={() => setError('')} class="absolute top-0 bottom-0 right-0 px-4 py-3">
                                    <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </span>
                            </div>
                        }
                        <form id="login-form" onSubmit={handleSubmit}>
                            <div className="flex flex-col mb-4">
                                <label className="mb-2 uppercase font-semibold text-sm text-grey-darkest" htmlFor="username">Korisnik</label>
                                <input onChange={event => setUsername(event.target.value)} autoComplete="username" className="border py-2 px-3 text-grey-darkest rounded" type="text" name="username" id="username" />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="mb-2 uppercase font-semibold text-sm text-grey-darkest" htmlFor="password">Lozinka</label>
                                <input onChange={event => setPassword(event.target.value)} className="border py-2 px-3 text-grey-darkest rounded" type="password" name="password" id="password" />
                            </div>
                        </form>
                    </div>
                    <div className="p-4 w-full">
                        <button form="login-form" disabled={loading} className="bg-primary text-white text-lg px-4 py-3 rounded-sm w-full focus:outline-none hover:bg-yellow-700">
                            { loading ? 'Obrađuje se...' : 'Prijava'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}