import React, { useState, useEffect } from 'react'
import { priceFormat } from '../utils/helper';

import api, {setApi} from "../utils/api";
import global from '../global';
import logo from '../assets/img/logo.png'


const SelectPosModal = (props) => {

    const {showSelectPosModal,setShowSelectPosModal} = props;
    const [invoiceDevices, setInvoiceDevices] = useState([])
    const [invoiceDeviceId, setInvoiceDeviceId] = useState({})
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getInvoiceDevices()
        //console.log('i fire once');
    }, [])

    function getInvoiceDevices({signal} = {}) {
        setLoading(true)

        return window.axios.get(`/invoice-devices`, {signal})
            .then(response => {
                //console.log(response.data.data);
                setInvoiceDevices(response.data.data)
            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
    }

    const saveInvoiceDevice = async () => {
        setProcessing(true)

        //console.log(invoiceDeviceId);

        var result = invoiceDevices.find(obj => {
            //console.log(obj.id);
          return obj.id === parseInt(invoiceDeviceId)
        })

        global.invoiceDevice = result
        localStorage.setItem('invoiceDevice', JSON.stringify(result));
        setApi()
        setProcessing(false)
        setShowSelectPosModal(false)
        convertImgToDataURLviaCanvas( result.invoice_space_logo, function( base64_data ) {
            //console.log( base64_data );
            localStorage.setItem('invoiceLogo', base64_data);
            window.location.reload()
        } );

    }

    var convertImgToDataURLviaCanvas = function(url, callback) {
      var img = new Image();

      img.crossOrigin = 'Anonymous';

      img.onload = function() {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        callback(dataURL);
        canvas = null;
      };

      img.src = url;
    }

    const SelectPos = () => {
        return (
            <div className="text-left w-full text-sm p-6 overflow-auto">
                <div className="text-center">
                    <img src={logo} className="w-10 m-auto filter grayscale" />
                    <h2 className="text-xl font-semibold">Maskice.hr</h2>
                    <p>Odaberite blagajnu</p>
                </div>
                <div className="flex mt-2 text-xs">

                        <div className="relative w-full max-w-full mx-auto mt-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <select
                              value={invoiceDeviceId}
                              onChange={e => setInvoiceDeviceId(e.target.value)}
                              className="w-full px-3 py-2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none appearance-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2">
                             <option key={0} value={0}>--------</option>
                              {invoiceDevices ? invoiceDevices.map((device) => {

                                  return <option key={device.id} value={device.id}>{device.full_name}</option>

                              }) : null}
                          </select>
                        </div>
                    </div>

                <hr className="my-2" />
                <div>

                </div>
            </div>
        )
    }

    return (
        <>
            { showSelectPosModal &&
            <>
                <div className="hide-print fixed w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center p-24">
                    <div onClick={() => setShowSelectPosModal(false)} className="fixed glass w-full h-screen left-0 top-0 z-0 opacity-100"></div>
                    <div className="w-96 rounded-sm bg-white shadow-xl overflow-hidden z-10 opacity-100 scale-100">
                        <SelectPos />
                        <div className="p-4 w-full">
                            <button disabled={processing} onClick={() => saveInvoiceDevice()} className="bg-primary hover:bg-yellow-400 text-black text-lg px-4 py-3 rounded-sm w-full focus:outline-none font-bold text-base">
                                { processing ? 'Procesuiranje..' : 'Spremi'}
                            </button>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default SelectPosModal
