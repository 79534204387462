import ContentLoader from "react-content-loader";

export default function ProductLoading(props) {
  return (
        <ContentLoader
            //   width={450}
            height={300}
            //   viewBox=""
            backgroundColor="#ffffff"
            foregroundColor="#f0f0f0"
            {...props}
        >
            <rect x="0" y="235" rx="4" ry="4" width="100" height="9"/>
            <rect x="160" y="235" rx="3" ry="3" width="100" height="9"/>
            <rect x="0" y="10" rx="10" ry="10" width="300" height="217"/>
        </ContentLoader>
  );
}

