
import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"
import user from "../Models/user";

const ProtectedRoute = ({children}) => {
    let location = useLocation();

    //console.log(user.isAuthenticated());

    if(!user.isAuthenticated()) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }


 return children

};

export default ProtectedRoute;