import React, { Component } from 'react';
import {Form, InputGroup} from 'react-bootstrap';

import {
    CalendarIcon,
} from "@heroicons/react/24/outline";

export default class TextFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: ""
        }
    }

    componentDidMount() {


    }
    handleClear = () => {
        this.setState({
            value: ''
        });
        this.props.applyFilters(this.state.data.name, '')
    };
    render() {

        return (
            <Form>
            <Form.Group className="mb-2">
                <Form.Label>{this.props.data.label}</Form.Label>
                    <InputGroup>
              <Form.Control
                  className={'rounded'}
                required
                type="text"
                value={this.state.value}
                placeholder={this.props.data.placeholder ? this.props.data.placeholder : 'Pretraži...'}
                  onChange={
                      (e) => {
                          console.log(e.target.value);
                          this.setState({ value: e.target.value })
                          this.props.applyFilters(this.state.data.name, e.target.value)
                      }

                  }
              />
                        {this.state.value ? <button className="inner-btn" onClick={this.handleClear}><i className="far fa-times"></i></button> : ''}

                          </InputGroup>
            </Form.Group>
          </Form>


        )

    }
}