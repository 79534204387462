class User {

    constructor() {
        this.init()
    }

    init() {
        this.id = localStorage.getItem('user_id')
        this.name = localStorage.getItem('user_name')
        this.surname = localStorage.getItem('user_surname')
        this.username = localStorage.getItem('user_username')
        this.email = localStorage.getItem('user_email')
        this.loggedIn = localStorage.getItem('user_authenticated')
    }

    /**
     *
     * @param data object
     * @param data.name string
     * @param data.email string
     * @param callback function
     */
    authenticated(data, callback) {
        localStorage.setItem('user_name', data.name)
        localStorage.setItem('user_surname', data.surname)
        localStorage.setItem('user_username', data.username)
        localStorage.setItem('user_id', data.id)
        localStorage.setItem('user_email', data.email)
        localStorage.setItem('user_authenticated', true)

        this.init()

        callback()
    }

    destroy() {
        localStorage.removeItem('user_name')
        localStorage.removeItem('user_surname')
        localStorage.removeItem('user_username')
        localStorage.removeItem('user_id')
        localStorage.removeItem('user_email')
        localStorage.removeItem('user_authenticated')
    }

    logout(callback) {
        this.destroy()

        typeof callback === 'function' && callback();
    }

    /**
     *
     * @return {boolean}
     */
    isAuthenticated() {
        return Boolean(this.loggedIn) === true
    }
}

export default new User()