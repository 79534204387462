import DataTableTailwind from "../Components/DataTableTailwind";
import {printReceipt} from '../Helpers/receipt'
import {useState} from "react";
import {processAxiosError, showError, showSuccess} from "../utils/helper";
import {getPdf} from "../Helpers/pdf";


function SalesIndex() {

    const [processing, setProcessing] = useState(false)
    const [tableKey, setTableKey] = useState(0)


    const formatColor = function (value, item, name) {

        let color = ''

        if (item.status !== 1) {
            color = 'text-red-600';
        }

        return <span key={'color-' + name + value} className={color}> {value} </span>;

    }

    const formatQuantity = function (value) {

        let color = ''

        if (value > 0) {
            color = 'badge  bg bg-primary';
        } else {
            color = 'badge  bg bg-black';
        }


        return <span key={'name-quantity-' + value} className={color}> {value > 0 ? '+' + value : value} </span>;

    }

    const formatAction = function (value, item) {

        if (value === "add") {
            return 'Dodano'
        } else if (value === "remove") {
            return 'Ulonjeno'
        } else if (value === "transfer") {
            return 'Poslano'
        } else if (value === "transfer-cancel") {
            return 'Slanje otkazano'
        } else {
            return item.id;
        }
    }
    const formatPrice = function (value, item, name) {

        value = value + ' ' + item.invoice_currency_symbol

        return formatColor(value, item, name);

    }
    const formatActions = function (value, item) {


        return (<>
            <button
                onClick={() => printReceipt(item)}
                className="bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 rounded inline-flex items-center">
                <span>Ispis</span>
            </button>
            {!item.fiskal_status && <button
                onClick={() => fiskalateSale(item)}
                className="ml-2 bg-black hover:bg-black-800 text-white py-2 px-4 rounded inline-flex items-center">
                <span>Fiskaliziraj</span>
            </button>
            }

            {item.invoice_real_id && <button
                onClick={() => getPdf(item.invoice_pdf)}
                className="ml-2 bg-green-600 hover:bg-green-800 text-white py-2 px-4 rounded inline-flex items-center">
                <span>PDF</span>
            </button>
            }

            {item.status == 1 &&
            <button
                onClick={() => stornSale(item)}
                className="ml-2 bg-red-600 hover:bg-red-800 text-white py-2 px-4 rounded inline-flex items-center">
                <span>Storniraj</span>
            </button>
            }

        </>)


    }

    const columns = [
		{name: "id", label: "ID", type: "bigIncrements", formater:formatColor},
        {name: "invoice_date", label: "Datum i vrijeme", type: "timestamp", formater:formatColor},
        {name: "invoice_total_with_tax", label: "Iznos", type: "timestamp", formater:formatPrice},
        {name: "invoice_payment_method", label: "Plaćanje", type: "timestamp", formater:formatColor},
        {name: "invoice_real_id", label: "Broj računa", type: "timestamp", formater:formatColor},
		{name: "action", label: "Akcija", type: "string", formater:formatActions},
    ];

    const actions = [];

    const filters = [];

    const stornSale = async (sale) => {
        setProcessing(true)

        await window.axios.delete('/sales/' + sale.id)
            .then((response) => {
                setProcessing(false)

                console.log(response)
                if (response.status === 201 && response.data.success === true) {
                    console.log(response.data.data)
                    //window.location.reload(false);
                    setTableKey(tableKey + 1);
                    showSuccess('Račun uspješno stoniran!')
                    //setShowReceiptModal(false)
                } else {
                    showError('Došlo je do greške! Error: ' + response.status)
                }
            })
            .catch((error) => {
                setProcessing(false)
                processAxiosError(error)

            })
    }
    const fiskalateSale = async (sale) => {
        setProcessing(true)

        await window.axios.post('/sales/' + sale.id + '/fiskalate')
            .then((response) => {
                setProcessing(false)

                console.log(response)
                if (response.status === 201 && response.data.success === true) {
                    console.log(response.data.data)
                    //window.location.reload(false);
                    setTableKey(tableKey + 1);
                    showSuccess('Račun uspješno fiskaliziran!')
                    //setShowReceiptModal(false)
                } else {
                    showError('Došlo je do greške! Error: ' + response.status)
                }
            })
            .catch((error) => {
                setProcessing(false)
                processAxiosError(error)

            })
    }
    return (
        <div>
            <DataTableTailwind key={'table-sales-' + tableKey} name="sales" url="/sales" columns={columns}
                               filters={filters}
                               actions={actions}/>
        </div>
    );


}

export default SalesIndex

